import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

export const getListFromStore = (state) => state.lists;

const defaultValue = {};
export const getListValuesFromStore = (state, listName) => _get(state, ['lists', listName, 'items'], defaultValue);

export const makeSelectFieldValue = () => createSelector(
    [
        (state, _, listName) => getListValuesFromStore(state, listName),
        (state, fieldName) => fieldName
    ],

    (listValues, fieldName) => listValues[fieldName] || false
);

export const makeSelectIsOneItemSelected = () => createSelector(
    [
        (state, listName) => getListValuesFromStore(state, listName),

    ],
    (listValues) => Object.keys(listValues).some((fieldName) => listValues[fieldName] === true)
);

export const makeSelectIsAllItemSelected = () => createSelector(
    [
        (state, listName) => getListValuesFromStore(state, listName),

    ],
    (listValues) => Object.keys(listValues).every((fieldName) => listValues[fieldName] === true)
);

export const makeSelectIsAtLeastOneItemAvailable = () => createSelector(
    [
        (state, listName) => getListValuesFromStore(state, listName),

    ],
    (listValues) => Object.keys(listValues).length > 0
);

export const makeSelectBulkCheckedState = () => createSelector(
    [
        (state, _, listName) => getListValuesFromStore(state, listName),
        (_, itemIdsToCheckFor) => itemIdsToCheckFor
    ],
    (listValues, itemIdsToCheckFor) => {
        const trueFields = [];
        const falseFields = [];
        let listItemKeysToCheckFor = Object.keys(listValues);
        if (itemIdsToCheckFor) {
            listItemKeysToCheckFor = _intersection(listItemKeysToCheckFor, itemIdsToCheckFor);
        }
        if (listItemKeysToCheckFor.length === 0) {
            return 'unchecked';
        }
        listItemKeysToCheckFor.forEach((fieldName) => {
            if (listValues[fieldName] === true) {
                trueFields.push(fieldName);
            } else {
                falseFields.push(fieldName);
            }
        });
        if (falseFields.length === listItemKeysToCheckFor.length) {
            return 'unchecked';
        }
        if (trueFields.length === listItemKeysToCheckFor.length) {
            return 'checked';
        }
        return 'indeterminate';
    }
);

export const makeSelectCheckedValues = () => createSelector(
    [
        getListValuesFromStore
    ],
    (listValues) => {
        const trueFields = [];
        Object.keys(listValues).forEach((fieldName) => {
            if (listValues[fieldName] === true) {
                trueFields.push(fieldName);
            }
        });
        return trueFields;
    }
);

export const makeSelectUnCheckedValues = () => createSelector(
    [
        (state, listName) => getListValuesFromStore(state, listName),
    ],
    (listValues) => {
        const trueFields = [];
        Object.keys(listValues).forEach((fieldName) => {
            if (listValues[fieldName] === false) {
                trueFields.push(fieldName);
            }
        });
        return trueFields;
    }
);

export const makeSelectTotalListItemsLength = () => createSelector(
    [
        getListValuesFromStore
    ],
    (listItems) => Object.keys(listItems).length
);

export const selectIsListInitialized = createSelector(
    [
        getListFromStore,
        (_, listName) => listName
    ],
    (list, listName) => !!_get(list, listName, false)
);
