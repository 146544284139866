export const LIMITS_GET_REQUEST = 'LIMITS_GET_REQUEST_BACKGROUND';
export const LIMITS_GET_SUCCESS = 'LIMITS_GET_SUCCESS_BACKGROUND';
export const LIMITS_GET_ERROR = 'LIMITS_GET_ERROR_BACKGROUND';

export const limitsGetRequest = () => ({
    type: LIMITS_GET_REQUEST
});

export const limitsGetSuccess = (spaceLimits, clientLimits) => ({
    type: LIMITS_GET_SUCCESS,
    payload: {
        spaceLimits,
        clientLimits
    }
});

export const limitsGetError = (error) => ({
    type: LIMITS_GET_ERROR,
    payload: { error }
});
