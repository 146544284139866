import { createSelector } from 'reselect';
import { makeSelectProfiles } from 'src/selectors/profiles';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import { selectSpaceLimits } from 'src/selectors/spaceLimits';
import { createShallowEqualSelector, defaultLoadingState, makePropertyExtractor } from 'src/selectors/utils';
import { platformTypes } from 'src/utils/profiles';
import { getCurrentProfileLimitCountAndType, listName } from 'src/utils/profileSearch';
import { generateAccountTempId } from 'src/utils/accountSearch';
import _get from 'lodash/get';
import { makeOrderBySorter } from 'src/selectors/accounts';

// inputBox search
export const getProfileSearchInput = (state) => state.profileSearch.inputBoxSearch.input;
export const getProfileSearchOutput = (state) => state.profileSearch.inputBoxSearch.output;
export const getProfileSearchFilter = (state) => state.profileSearch.inputBoxSearch.filter;
export const getProfileSearchIsPending = (state) => state.profileSearch.inputBoxSearch.isPending;

// authenticated search
export const getSocialNetworkAuthenticatedSearch = (state) => state.profileSearch.authenticatedSearch;
export const selectIsAuthenticatedSearchResultPage = (state) => state.profileSearch.isAuthenticatedSearchResultPage;

// auth profile search
export const getAuthProfileSearchState = (state) => state.profileSearch.authProfileSearch.search;
export const getAuthProfileSearchOutput = (state) => state.profileSearch.authProfileSearch.authProfileSearchOutput;

// all result
export const getProfileSearchResultsById = (state) => state.profileSearch.searchedProfilesById;

export const makeSelectAlreadyAddedProfilesByTempIds = () => {
    const selectProfiles = makeSelectProfiles();
    return createSelector(
        [
            selectProfiles,
            (_, tempIds) => tempIds
        ],
        (profiles, tempIds) => {
            const existedTempIds = [];
            profiles.forEach((profile) => {
                const currentTempId = generateAccountTempId(profile);
                if (tempIds.indexOf(currentTempId) >= 0) {
                    existedTempIds.push(currentTempId);
                }
            });
            return existedTempIds;
        }
    );
};

export const makeSelectProfileSearchOutput = () => createSelector(
    [
        getProfileSearchOutput,
        getProfileSearchFilter,
        getProfileSearchResultsById
    ],
    (output, filter, resultsById) => {
        const {
            keyword, resultType, hints, errors, profileIds, failedLinks, outOfProfileLimitLinks
        } = output;
        // group profile ids by network
        const profileIdsPerNetwork = {};
        platformTypes.forEach((platformType) => {
            profileIdsPerNetwork[platformType] = [];
        });
        profileIds.forEach((profileId) => {
            const profile = resultsById[profileId];
            if (profile) {
                profileIdsPerNetwork[profile.platformType].push(profileId);
            }
        });

        let filteredAndOrderedProfileIds = [];
        if (filter !== 'all') {
            // If a filter is present, show all results for a given network
            filteredAndOrderedProfileIds = profileIdsPerNetwork[filter] || [];
        } else {
            // If no filter is present, show top 3 per network first
            platformTypes.forEach((platformType) => {
                filteredAndOrderedProfileIds.push(...profileIdsPerNetwork[platformType].slice(0, 3));
            });

            // Then show rest of results per network below
            platformTypes.forEach((platformType) => {
                const networkSize = profileIdsPerNetwork[platformType].length;
                filteredAndOrderedProfileIds.push(...profileIdsPerNetwork[platformType].slice(3, networkSize));
            });
        }

        return {
            keyword,
            profileIds: filteredAndOrderedProfileIds,
            totalResultCount: profileIds.length,
            resultType,
            hints,
            errors,
            failedLinks,
            outOfProfileLimitLinks
        };
    }
);

export const makeSelectProfileSearchedById = () => createSelector(
    [
        getProfileSearchResultsById,
        (_, id) => id
    ],
    (searchedProfile, id) => searchedProfile[id] || null
);

export const makeSelectSearchedProfilesByIds = () => {
    const sorter = makeOrderBySorter();
    return createSelector(
        [
            getProfileSearchResultsById,
            (_, ids) => ids
        ],
        (searchedProfiles, ids) => {
            const searchedProfilesArray = ids.map((id) => searchedProfiles[id]);
            return sorter(searchedProfilesArray, 'alphabetically');
        }
    );
};

export const makeSelectProfileTempIdsSortedAlphabitically = () => {
    const selectSearchedProfilesByIds = makeSelectSearchedProfilesByIds();
    const idExtractor = makePropertyExtractor();
    return createSelector(
        [
            selectSearchedProfilesByIds
        ], (result) => idExtractor(result)
    );
};

export const makeSelectCurrentProfileLimitCountAndType = () => {
    const selectCheckedValues = makeSelectCheckedValues();
    return createShallowEqualSelector(
        [
            selectSpaceLimits,
            (state) => selectCheckedValues(state, listName)
        ],
        (spaceLimits, checkedSearchedProfiles) => getCurrentProfileLimitCountAndType(spaceLimits, checkedSearchedProfiles.length)
    );
};

export const makeSelectAuthProfileSearchState = () => createSelector(
    [
        getAuthProfileSearchState
    ],
    (authProfileSearchState) => _get(authProfileSearchState, ['state'], defaultLoadingState)
);
