export const ADMIN_SETTINGS_GET_SETTINGS_REQUEST = 'ADMIN_SETTINGS_GET_SETTINGS_REQUEST';
export const ADMIN_SETTINGS_GET_SETTINGS_SUCCESS = 'ADMIN_SETTINGS_GET_SETTINGS_SUCCESS';
export const ADMIN_SETTINGS_GET_SETTINGS_ERROR = 'ADMIN_SETTINGS_GET_SETTINGS_ERROR';

export const ACCOUNT_GET_DATA_REQUEST = 'ACCOUNT_GET_DATA_REQUEST';
export const ACCOUNT_GET_DATA_SUCCESS = 'ACCOUNT_GET_DATA_SUCCESS';
export const ACCOUNT_GET_DATA_ERROR = 'ACCOUNT_GET_DATA_ERROR';

export const BILLING_LINK_GENERATE_REQUEST = 'BILLING_LINK_GENERATE_REQUEST';
export const BILLING_LINK_GENERATE_SUCCESS = 'BILLING_LINK_GENERATE_SUCCESS';
export const BILLING_LINK_GENERATE_ERROR = 'BILLING_LINK_GENERATE_ERROR';

export const adminSettingsGetSettingsRequest = () => ({
    type: ADMIN_SETTINGS_GET_SETTINGS_REQUEST
});

export const adminSettingsGetSettingsSuccess = (spaces, users, clientLimits, accountData, apiAccess) => ({
    type: ADMIN_SETTINGS_GET_SETTINGS_SUCCESS,
    payload: {
        spaces,
        users,
        clientLimits,
        accountData,
        apiAccess
    }
});

export const adminSettingsGetSettingsError = (error) => ({
    type: ADMIN_SETTINGS_GET_SETTINGS_ERROR,
    payload: {
        error
    }
});

export const accountGetDataRequest = () => ({
    type: ACCOUNT_GET_DATA_REQUEST
});

export const accountGetDataSuccess = (results) => ({
    type: ACCOUNT_GET_DATA_SUCCESS,
    payload: { results }
});

export const accountGetDataError = (error) => ({
    type: ACCOUNT_GET_DATA_ERROR,
    payload: { error }
});

export const billingLinkGenerateRequest = () => ({
    type: BILLING_LINK_GENERATE_REQUEST
});

export const billingLinkGenerateSuccess = () => ({
    type: BILLING_LINK_GENERATE_SUCCESS
});

export const billingLinkGenerateError = (error) => ({
    type: BILLING_LINK_GENERATE_ERROR,
    payload: { error }
});
