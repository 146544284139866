import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';

export const formName = 'metricBuilderForm';
export const isVisualizationOfTypeApi = (id) => id === '12';
const sidebarFields = ['metricName', 'description', 'metricSummary', 'useCases'];

export const isValidWithoutMetricNameValidation = (fieldErrors, valid) => {
    if (!_isEmpty(fieldErrors)) {
        if (Object.keys(fieldErrors).length === 1 && _has(fieldErrors, 'metricName', false)) {
            return true;
        }
        return false;
    }
    return valid;
};

// we double check if fields have error, because if the field is hidden from the form (sidebar fields), the flag is valid
export const isFormActuallyValid = (fieldErrors, valid) => (!_isEmpty(fieldErrors) ? false : valid);

export const hasAnySidebarFieldsError = (fieldErrors) => {
    if (!_isEmpty(fieldErrors)) {
        return Object.keys(fieldErrors).some((r) => sidebarFields.indexOf(r) >= 0);
    }
    return false;
};
