export const ACCOUNT_ENTITIES_GET_REQUEST = 'ACCOUNT_ENTITIES_GET_REQUEST';
export const ACCOUNT_ENTITIES_GET_SUCCESS = 'ACCOUNT_ENTITIES_GET_SUCCESS';
export const ACCOUNT_ENTITIES_GET_ERROR = 'ACCOUNT_ENTITIES_GET_ERROR';

export const accountEntitiesGetRequest = () => ({
    type: ACCOUNT_ENTITIES_GET_REQUEST
});

export const accountEntitiesGetSuccess = (accountAuthenticationEntities, profiles, adAccounts, groups) => ({
    type: ACCOUNT_ENTITIES_GET_SUCCESS,
    payload: {
        accountAuthenticationEntities, profiles, adAccounts, groups
    }
});

export const accountEntitiesGetError = (error) => ({
    type: ACCOUNT_ENTITIES_GET_ERROR,
    payload: { error }
});
