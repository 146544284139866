export const PROFILE_GET_AUDIENCE_DATA_OVERVIEW_REQUEST = 'PROFILE_GET_AUDIENCE_DATA_OVERVIEW_REQUEST';
export const PROFILE_GET_AUDIENCE_DATA_OVERVIEW_ERROR = 'PROFILE_GET_AUDIENCE_DATA_OVERVIEW_ERROR';
export const PROFILE_GET_AUDIENCE_DATA_OVERVIEW_SUCCESS = 'PROFILE_GET_AUDIENCE_DATA_OVERVIEW_SUCCESS';

export const profileGetAudienceDataOverviewRequest = (profileId) => ({
    type: PROFILE_GET_AUDIENCE_DATA_OVERVIEW_REQUEST,
    payload: {
        profileId
    }
});

export const profileGetAudienceDataOverviewSuccess = (profileId, audienceData, warning = null) => ({
    type: PROFILE_GET_AUDIENCE_DATA_OVERVIEW_SUCCESS,
    payload: { profileId, audienceData, warning }
});

export const profileGetAudienceDataOverviewError = (profileId, error) => ({
    type: PROFILE_GET_AUDIENCE_DATA_OVERVIEW_ERROR,
    payload: { profileId, error }
});
