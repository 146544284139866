export const PROFILE_EVENTS_GET_REQUEST = 'PROFILE_EVENTS_GET_REQUEST';
export const PROFILE_EVENTS_GET_SUCCESS = 'PROFILE_EVENTS_GET_SUCCESS';
export const PROFILE_EVENTS_GET_ERROR = 'PROFILE_EVENTS_GET_ERROR';

export const profileEventsGetRequest = (profileId) => ({
    type: PROFILE_EVENTS_GET_REQUEST,
    payload: { profileId }
});

export const profileEventsGetSuccess = (profileId, profileEvents) => ({
    type: PROFILE_EVENTS_GET_SUCCESS,
    payload: { profileId, profileEvents }
});

export const profileEventsGetError = (profileId, error) => ({
    type: PROFILE_EVENTS_GET_ERROR,
    payload: { profileId, error }
});
