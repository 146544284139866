export const PROFILE_HEALTH_STATUS_GET_REQUEST = 'PROFILE_HEALTH_STATUS_GET_REQUEST';
export const PROFILE_HEALTH_STATUS_GET_SUCCESS = 'PROFILE_HEALTH_STATUS_GET_SUCCESS';
export const PROFILE_HEALTH_STATUS_GET_ERROR = 'PROFILE_HEALTH_STATUS_GET_ERROR';

export const PROFILES_HEALTH_STATUS_GET_REQUEST = 'PROFILES_HEALTH_STATUS_GET_REQUEST';
export const PROFILES_HEALTH_STATUS_GET_SUCCESS = 'PROFILES_HEALTH_STATUS_GET_SUCCESS';
export const PROFILES_HEALTH_STATUS_GET_ERROR = 'PROFILES_HEALTH_STATUS_GET_ERROR';

export const ALL_PROFILE_HEALTH_STATUS_GET_SUCCESS = 'ALL_PROFILE_HEALTH_STATUS_GET_SUCCESS';
export const ALL_PROFILE_HEALTH_STATUS_GET_ERROR = 'ALL_PROFILE_HEALTH_STATUS_GET_ERROR';

export const PROFILE_HEALTH_METRIC_DATA_GET_REQUEST = 'PROFILE_HEALTH_METRIC_DATA_GET_REQUEST';
export const PROFILE_HEALTH_METRIC_DATA_GET_SUCCESS = 'PROFILE_HEALTH_METRIC_DATA_GET_SUCCESS';
export const PROFILE_HEALTH_METRIC_DATA_GET_ERROR = 'PROFILE_HEALTH_METRIC_DATA_GET_ERROR';

export const TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_REQUEST = 'TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_REQUEST';
export const TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_SUCCESS = 'TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_SUCCESS';
export const TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_ERROR = 'TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_ERROR';

export const profileHealthMetricDataGetRequest = (profileId) => ({
    type: PROFILE_HEALTH_METRIC_DATA_GET_REQUEST,
    payload: { profileId }
});

export const profileHealthDataGetSuccess = (profileId, healthMetricData) => ({
    type: PROFILE_HEALTH_METRIC_DATA_GET_SUCCESS,
    payload: { profileId, healthMetricData }
});

export const profileHealthDataGetError = (profileId, error) => ({
    type: PROFILE_HEALTH_METRIC_DATA_GET_ERROR,
    payload: { profileId, error }
});

export const profileHealthStatusGetRequest = (profileId) => ({
    type: PROFILE_HEALTH_STATUS_GET_REQUEST,
    payload: { profileId }
});

export const profileHealthStatusGetSuccess = (profileId, status) => ({
    type: PROFILE_HEALTH_STATUS_GET_SUCCESS,
    payload: { profileId, status }
});

export const profileHealthStatusGetError = (profileId, error) => ({
    type: PROFILE_HEALTH_STATUS_GET_ERROR,
    payload: { profileId, error }
});

export const profilesHealthStatusGetRequest = (profileIds) => ({
    type: PROFILES_HEALTH_STATUS_GET_REQUEST,
    payload: { profileIds }
});

export const profilesHealthStatusGetSuccess = (profilesHealthStatus) => ({
    type: PROFILES_HEALTH_STATUS_GET_SUCCESS,
    payload: { profilesHealthStatus }
});

export const profilesHealthStatusGetError = (error) => ({
    type: PROFILES_HEALTH_STATUS_GET_ERROR,
    payload: { error }
});

export const allProfileHealthStatusGetSuccess = (profilesHealthStatus) => ({
    type: ALL_PROFILE_HEALTH_STATUS_GET_SUCCESS,
    payload: { profilesHealthStatus }
});

export const allProfileHealthStatusGetError = (error) => ({
    type: ALL_PROFILE_HEALTH_STATUS_GET_ERROR,
    payload: { error }
});

export const tagProfileAsInstagramBusinessProfileRequest = (profileId, platformId, platformUsername) => ({
    type: TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_REQUEST,
    payload: {
        profileId,
        platformId,
        platformUsername
    }
});

export const tagProfileAsInstagramBusinessProfileSuccess = (profileId, profilesHealth) => ({
    type: TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_SUCCESS,
    payload: {
        profileId,
        profilesHealth
    }
});

export const tagProfileAsInstagramBusinessProfileError = (error) => ({
    type: TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_ERROR,
    payload: error
});
