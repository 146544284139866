import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { defaultLoadingState } from 'src/selectors/utils';

export const getPackageStatus = (state) => state.adminSettings.packageStatus;
export const getPackageInfo = (state) => state.adminSettings.packageInfo;
export const getCreditCard = (state) => state.adminSettings.creditCard;
export const getBillingAddress = (state) => state.adminSettings.billingAddress;
export const getApiAccess = (state) => state.adminSettings.apiAccess;
export const getAsyncStates = (state) => state.adminSettings.asyncStates;

export const makeSelectAdminSettingsState = () => createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['adminSettings', 'state'], defaultLoadingState)
);

export const makeSelectIsBillingLinkGenerating = () => createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['generateBillingLink', 'state', 'isPending'], false)
);

export const selectAccountDataState = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['accountData', 'state'], defaultLoadingState)
);

export const makeSelectBillingAddressAndCreditCardDetail = () => createSelector(
    [
        getBillingAddress,
        getCreditCard,
    ],
    (billingAddress, creditCard) => ({ billingAddress, creditCard })
);

export const selectBillingAndInvoiceLoadingState = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['billingAndInvoiceLoadingState'])
);
