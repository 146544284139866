import { createFormAction } from 'redux-form-saga';

export const GET_DATA_PUSH_TASKS_REQUEST = 'GET_DATA_PUSH_TASKS_REQUEST';
export const GET_DATA_PUSH_TASKS_SUCCESS = 'GET_DATA_PUSH_TASKS_SUCCESS';
export const GET_DATA_PUSH_TASKS_ERROR = 'GET_DATA_PUSH_TASKS_ERROR';

export const GET_DATA_PUSH_TASK_REQUEST = 'GET_DATA_PUSH_TASK_REQUEST';
export const GET_DATA_PUSH_TASK_SUCCESS = 'GET_DATA_PUSH_TASK_SUCCESS';
export const GET_DATA_PUSH_TASK_ERROR = 'GET_DATA_PUSH_TASK_ERROR';

export const DATA_PUSH_TASK_DELETE_REQUEST = 'DATA_PUSH_TASK_DELETE_REQUEST';
export const DATA_PUSH_TASK_DELETE_SUCCESS = 'DATA_PUSH_TASK_DELETE_SUCCESS';
export const DATA_PUSH_TASK_DELETE_ERROR = 'DATA_PUSH_TASK_DELETE_ERROR';

export const DATA_PUSH_TASK_CREATE_VALIDATION_REQUEST = 'DATA_PUSH_TASK_CREATE_VALIDATION_REQUEST';
export const DATA_PUSH_TASK_CREATE_VALIDATION_SUCCESS = 'DATA_PUSH_TASK_CREATE_VALIDATION_SUCCESS';
export const DATA_PUSH_TASK_CREATE_VALIDATION_ERROR = 'DATA_PUSH_TASK_CREATE_VALIDATION_ERROR';

export const DATA_PUSH_TASK_UPDATE_REQUEST = 'DATA_PUSH_TASK_UPDATE_REQUEST';
export const DATA_PUSH_TASK_UPDATE_SUCCESS = 'DATA_PUSH_TASK_UPDATE_SUCCESS';
export const DATA_PUSH_TASK_UPDATE_ERROR = 'DATA_PUSH_TASK_UPDATE_ERROR';

export const DATA_PUSH_TASK_ACTIVATE_REQUEST = 'DATA_PUSH_TASK_ACTIVATE_REQUEST';
export const DATA_PUSH_TASK_ACTIVATE_SUCCESS = 'DATA_PUSH_TASK_ACTIVATE_SUCCESS';
export const DATA_PUSH_TASK_ACTIVATE_ERROR = 'DATA_PUSH_TASK_ACTIVATE_ERROR';

export const DATA_PUSH_TASK_DEACTIVATE_REQUEST = 'DATA_PUSH_TASK_DEACTIVATE_REQUEST';
export const DATA_PUSH_TASK_DEACTIVATE_SUCCESS = 'DATA_PUSH_TASK_DEACTIVATE_SUCCESS';
export const DATA_PUSH_TASK_DEACTIVATE_ERROR = 'DATA_PUSH_TASK_DEACTIVATE_ERROR';

export const DATA_PUSH_TASK_CREATE_REQUEST = 'DATA_PUSH_TASK_CREATE_REQUEST';
export const DATA_PUSH_TASK_CREATE_SUCCESS = 'DATA_PUSH_TASK_CREATE_SUCCESS';
export const DATA_PUSH_TASK_CREATE_ERROR = 'DATA_PUSH_TASK_CREATE_ERROR';

export const DATA_PUSH_TASK_DESTINATION_UPDATE_REQUEST = 'DATA_PUSH_TASK_DESTINATION_UPDATE_REQUEST';
export const DATA_PUSH_TASK_DESTINATION_UPDATE_SUCCESS = 'DATA_PUSH_TASK_DESTINATION_UPDATE_SUCCESS';
export const DATA_PUSH_TASK_DESTINATION_UPDATE_ERROR = 'DATA_PUSH_TASK_DESTINATION_UPDATE_ERROR';

export const DATA_PUSH_TASK_DRY_RUN_REQUEST = 'DATA_PUSH_TASK_DRY_RUN_REQUEST';
export const DATA_PUSH_TASK_DRY_RUN_SUCCESS = 'DATA_PUSH_TASK_DRY_RUN_SUCCESS';
export const DATA_PUSH_TASK_DRY_RUN_ERROR = 'DATA_PUSH_TASK_DRY_RUN_ERROR';

export const DATA_PUSH_TASK_SCHEDULE_NOW_REQUEST = 'DATA_PUSH_TASK_SCHEDULE_NOW_REQUEST';
export const DATA_PUSH_TASK_SCHEDULE_NOW_SUCCESS = 'DATA_PUSH_TASK_SCHEDULE_NOW_SUCCESS';
export const DATA_PUSH_TASK_SCHEDULE_NOW_ERROR = 'DATA_PUSH_TASK_SCHEDULE_NOW_ERROR';

export const GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_REQUEST = 'GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_REQUEST';
export const GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_SUCCESS = 'GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_SUCCESS';
export const GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_ERROR = 'GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_ERROR';

export const DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_REQUEST = 'DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_REQUEST';
export const DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_SUCCESS = 'DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_SUCCESS';
export const DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_ERROR = 'DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_ERROR';

export const TOGGLE_IN_ACTIVE_DATA_PUSH_TASKS = 'TOGGLE_IN_ACTIVE_DATA_PUSH_TASKS';

export const SORT_DATA_PUSH_TASK = 'SORT_DATA_PUSH_TASK';

export const getDataPushTasksRequest = () => ({
    type: GET_DATA_PUSH_TASKS_REQUEST
});

export const getDataPushTasksError = (error) => ({
    type: GET_DATA_PUSH_TASKS_ERROR,
    payload: { error }
});

export const getDataPushTasksSuccess = (dataPushTasks, dataPushTaskDestinations, latestDataPushTaskLogs) => ({
    type: GET_DATA_PUSH_TASKS_SUCCESS,
    payload: { dataPushTasks, dataPushTaskDestinations, latestDataPushTaskLogs }
});

export const getDataPushTaskRequest = (id) => ({
    type: GET_DATA_PUSH_TASK_REQUEST,
    payload: { id }
});

export const getDataPushTaskSuccess = (id, dataPushTask, dataPushTaskDestination, dataPushTaskLog) => ({
    type: GET_DATA_PUSH_TASK_SUCCESS,
    payload: {
        id, dataPushTask, dataPushTaskDestination, dataPushTaskLog
    }
});

export const getDataPushTaskError = (id, error) => ({
    type: GET_DATA_PUSH_TASK_ERROR,
    payload: { id, error }
});

export const toggleInactiveDataPushTasks = () => ({
    type: TOGGLE_IN_ACTIVE_DATA_PUSH_TASKS
});

export const sortDataPushTask = (sortBy) => ({
    type: SORT_DATA_PUSH_TASK,
    payload: { sortBy }
});

export const dataPushTaskCreateValidationRequest = ({
    dataSource,
    dataSourceColumns,
    dynamicDate,
    timezone,
    interval,
    dataPushTimeColumnName,
    deliveryCronExpression
}) => ({
    payload: {
        dataSource,
        dataSourceColumns,
        dynamicDate,
        timezone,
        interval,
        dataPushTimeColumnName,
        deliveryCronExpression
    },
    type: DATA_PUSH_TASK_CREATE_VALIDATION_REQUEST
});

export const dataPushTaskCreateValidationSuccess = () => ({
    type: DATA_PUSH_TASK_CREATE_VALIDATION_SUCCESS
});

export const dataPushTaskCreateValidationError = (error) => ({
    type: DATA_PUSH_TASK_CREATE_VALIDATION_ERROR,
    payload: error
});

export const dataPushTaskCreateValidationRequestFormAction = createFormAction(dataPushTaskCreateValidationRequest, [DATA_PUSH_TASK_CREATE_VALIDATION_SUCCESS, DATA_PUSH_TASK_CREATE_VALIDATION_ERROR]);

export const dataPushTaskUpdateRequest = ({
    dataPushTaskId,
    dataSource,
    dataSourceColumns,
    dynamicDate,
    timezone,
    interval,
    dataPushTimeColumnName,
    deliveryCronExpression
}) => ({
    payload: {
        dataPushTaskId,
        dataSource,
        dataSourceColumns,
        dynamicDate,
        timezone,
        interval,
        dataPushTimeColumnName,
        deliveryCronExpression
    },
    type: DATA_PUSH_TASK_UPDATE_REQUEST
});

export const dataPushTaskUpdateSuccess = (dataPushTask, dataPushTaskDestination) => ({
    type: DATA_PUSH_TASK_UPDATE_SUCCESS,
    payload: { dataPushTask, dataPushTaskDestination }
});

export const dataPushTaskUpdateError = (error) => ({
    type: DATA_PUSH_TASK_UPDATE_ERROR,
    payload: error
});

export const dataPushTaskUpdateRequestFormAction = createFormAction(dataPushTaskUpdateRequest, [DATA_PUSH_TASK_UPDATE_SUCCESS, DATA_PUSH_TASK_UPDATE_ERROR]);

export const dataPushTaskRemoveRequest = ({ dataPushTaskId }) => ({
    type: DATA_PUSH_TASK_DELETE_REQUEST,
    payload: { dataPushTaskId }
});

export const dataPushTaskDeleteSuccess = (dataPushTaskId) => ({
    type: DATA_PUSH_TASK_DELETE_SUCCESS,
    payload: { dataPushTaskId }
});

export const DataPushTaskDeleteError = (error) => ({
    type: DATA_PUSH_TASK_DELETE_ERROR,
    payload: error
});

export const dataPushTaskDeleteRequestFormAction = createFormAction(dataPushTaskRemoveRequest, [DATA_PUSH_TASK_DELETE_SUCCESS, DATA_PUSH_TASK_DELETE_ERROR]);

export const dataPushTaskActivateRequest = (dataPushTaskId) => ({
    type: DATA_PUSH_TASK_ACTIVATE_REQUEST,
    payload: { dataPushTaskId }
});

export const dataPushTaskActivateSuccess = (dataPushTaskId, dataPushTask) => ({
    type: DATA_PUSH_TASK_ACTIVATE_SUCCESS,
    payload: { dataPushTaskId, dataPushTask }
});

export const dataPushTaskActivateError = (dataPushTaskId, error) => ({
    type: DATA_PUSH_TASK_ACTIVATE_ERROR,
    payload: { dataPushTaskId, error }
});

export const dataPushTaskDeactivateRequest = (dataPushTaskId) => ({
    type: DATA_PUSH_TASK_DEACTIVATE_REQUEST,
    payload: { dataPushTaskId }
});

export const dataPushTaskDeactivateSuccess = (dataPushTaskId, dataPushTask) => ({
    type: DATA_PUSH_TASK_DEACTIVATE_SUCCESS,
    payload: { dataPushTaskId, dataPushTask }
});

export const dataPushTaskDeactivateError = (dataPushTaskId, error) => ({
    type: DATA_PUSH_TASK_DEACTIVATE_ERROR,
    payload: { dataPushTaskId, error }
});

export const dataPushTaskDestinationCreateRequest = ({
    dataPushTaskId,
    destinationType,
    googleBigQueryDatasetId,
    googleBigQueryProjectId,
    googleBigQueryTableId,
    googleBigQueryAuth,
    googleBigQueryTimePartitioningType,
    googleBigQueryTimePartitioningField,
    awsS3AccessKeyId,
    awsS3SecretAccessKey,
    awsS3Region,
    awsS3Bucket,
    awsS3FilePrefix,
    awsS3FileFormat
}) => ({
    payload: {
        dataPushTaskId,
        destinationType,
        googleBigQueryProjectId,
        googleBigQueryDatasetId,
        googleBigQueryTableId,
        googleBigQueryAuth,
        googleBigQueryTimePartitioningType,
        googleBigQueryTimePartitioningField,
        awsS3AccessKeyId,
        awsS3SecretAccessKey,
        awsS3Region,
        awsS3Bucket,
        awsS3FilePrefix,
        awsS3FileFormat
    },
    type: DATA_PUSH_TASK_CREATE_REQUEST
});

export const dataPushTaskCreateSuccess = (dataPushTask, dataPushTaskDestination) => ({
    type: DATA_PUSH_TASK_CREATE_SUCCESS,
    payload: { dataPushTask, dataPushTaskDestination }
});

export const dataPushTaskCreateError = (error) => ({
    type: DATA_PUSH_TASK_CREATE_ERROR,
    payload: error
});

export const dataPushTaskCreateRequestFormAction = createFormAction(dataPushTaskDestinationCreateRequest, [DATA_PUSH_TASK_CREATE_SUCCESS, DATA_PUSH_TASK_CREATE_ERROR]);

export const dataPushTaskDestinationUpdateRequest = ({
    destinationId,
    dataPushTaskId,
    destinationType,
    googleBigQueryDatasetId,
    googleBigQueryProjectId,
    googleBigQueryTableId,
    googleBigQueryAuth,
    googleBigQueryTimePartitioningType,
    googleBigQueryTimePartitioningField,
    awsS3AccessKeyId,
    awsS3SecretAccessKey,
    awsS3Region,
    awsS3Bucket,
    awsS3FilePrefix,
    awsS3FileFormat
}) => ({
    payload: {
        destinationId,
        dataPushTaskId,
        destinationType,
        googleBigQueryProjectId,
        googleBigQueryDatasetId,
        googleBigQueryTableId,
        googleBigQueryAuth,
        googleBigQueryTimePartitioningType,
        googleBigQueryTimePartitioningField,
        awsS3AccessKeyId,
        awsS3SecretAccessKey,
        awsS3Region,
        awsS3Bucket,
        awsS3FilePrefix,
        awsS3FileFormat
    },
    type: DATA_PUSH_TASK_DESTINATION_UPDATE_REQUEST
});

export const dataPushTaskDestinationUpdateSuccess = (dataPushTask, dataPushTaskDestination) => ({
    type: DATA_PUSH_TASK_DESTINATION_UPDATE_SUCCESS,
    payload: { dataPushTask, dataPushTaskDestination }
});

export const dataPushTaskDestinationUpdateError = (error) => ({
    type: DATA_PUSH_TASK_DESTINATION_UPDATE_ERROR,
    payload: error
});

export const dataPushTaskDestinationUpdateRequestFormAction = createFormAction(dataPushTaskDestinationUpdateRequest, [DATA_PUSH_TASK_DESTINATION_UPDATE_SUCCESS, DATA_PUSH_TASK_DESTINATION_UPDATE_ERROR]);

export const dataPushTaskDryRunRequest = (dataPushTaskId) => ({
    type: DATA_PUSH_TASK_DRY_RUN_REQUEST,
    payload: { dataPushTaskId }
});

export const dataPushTaskDryRunSuccess = (dataPushTaskId) => ({
    type: DATA_PUSH_TASK_DRY_RUN_SUCCESS,
    payload: { dataPushTaskId }
});

export const dataPushTaskDryRunError = (dataPushTaskId, error) => ({
    type: DATA_PUSH_TASK_DRY_RUN_ERROR,
    payload: { dataPushTaskId, error }
});

export const dataPushTaskScheduleNowRequest = (dataPushTaskId) => ({
    type: DATA_PUSH_TASK_SCHEDULE_NOW_REQUEST,
    payload: { dataPushTaskId }
});

export const dataPushTaskScheduleNowSuccess = (dataPushTaskId) => ({
    type: DATA_PUSH_TASK_SCHEDULE_NOW_SUCCESS,
    payload: { dataPushTaskId }
});

export const dataPushTaskScheduleNowError = (dataPushTaskId, error) => ({
    type: DATA_PUSH_TASK_SCHEDULE_NOW_ERROR,
    payload: { dataPushTaskId, error }
});

export const googleBigQueryDestinationCreateTableRequest = ({ dataPushTaskId, destinationId }) => ({
    type: GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_REQUEST,
    payload: { dataPushTaskId, destinationId }
});

export const googleBigQueryDestinationCreateTableSuccess = () => ({
    type: GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_SUCCESS
});

export const googleBigQueryDestinationCreateTableError = (error) => ({
    type: GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_ERROR,
    payload: error
});

export const googleBigQueryDestinationCreateTableRequestFormAction = createFormAction(googleBigQueryDestinationCreateTableRequest, [GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_SUCCESS, GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_ERROR]);

export const dataPushTaskDestinationConnectionTestRequest = (dataPushTaskId, destinationId) => ({
    type: DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_REQUEST,
    payload: { dataPushTaskId, destinationId }
});

export const dataPushTaskDestinationConnectionTestSuccess = (destinationId) => ({
    type: DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_SUCCESS,
    payload: { destinationId }
});

export const dataPushTaskDestinationConnectionTestError = (destinationId, error) => ({
    type: DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_ERROR,
    payload: { destinationId, error }
});
