import { getNewLinesWithoutEmptyStrings } from 'src/utils/array';
import { isValidHttpUrl } from 'src/utils/string';

export const generateProfileSearchResultProfileId = (profile) => profile.platformType + profile.platformId;

export const listName = 'profileSearch';

export const getCurrentProfileLimitCountAndType = (spaceLimits, selectedProfileCount = 0) => {
    const { usage, limits } = spaceLimits;
    const profilesUsed = usage.profiles;
    const profilesTotal = limits.profiles;
    const profilesLeft = profilesTotal - profilesUsed;

    if (profilesLeft - selectedProfileCount <= 0) {
        return {
            count: 0,
            type: 'profile'
        };
    }

    const swapsUsed = usage.profileSwaps;
    const swapsTotal = limits.profileSwaps;
    const swapsLeft = swapsTotal - swapsUsed;

    if (swapsLeft - selectedProfileCount <= 0) {
        return {
            count: 0,
            type: 'swap'
        };
    }

    return {
        count: profilesLeft - selectedProfileCount,
        type: 'none'
    };
};

export const getProfileAddingStateIconAndLabel = (addingState) => {
    const { isPending, error, success } = addingState;
    if (isPending) {
        return {
            icon: 'pending',
            label: 'Adding'
        };
    }
    if (error) {
        return {
            icon: 'close',
            label: 'Failed to add.'
        };
    }
    if (success) {
        return {
            icon: 'success',
            label: 'Added successfully'
        };
    }
    return null;
};

export const getValidAndInvalidLinks = (links) => {
    const validLinks = [];
    const inValidLinks = [];
    links.forEach((line) => {
        if (isValidHttpUrl(line)) {
            validLinks.push(line);
        } else {
            inValidLinks.push(line);
        }
    });
    return {
        validLinks,
        inValidLinks
    };
};

export const extractLinksFromInput = (input) => getValidAndInvalidLinks(getNewLinesWithoutEmptyStrings(input));

export const checkIfProfileUsernameChanged = (existingProfile, searchedProfile) => {
    const { platformId, platformUsername, platformType } = searchedProfile;
    if (platformType === 'tiktok' || platformType === 'instagram') {
        return platformId === existingProfile.platformId && platformUsername !== existingProfile.platformUsername;
    }
    return false;
};
