import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { getLoggedInUserId } from 'src/selectors/loggedInUser';
import { makeStringSorter } from 'src/selectors/utils';
import _includes from 'lodash/includes';

const getUserIdsFromStore = (state) => state.entities.users.allIds;
const getUsersByIdFromStore = (state) => state.entities.users.byId;
const getUsersAsyncStatesFromStore = (state) => state.entities.users.asyncStatesByAction;

const getUserByIdFromStore = (state, id) => {
    const users = getUsersByIdFromStore(state);
    return users[id] || false;
};

export const selectUsers = createSelector(
    [
        getUserIdsFromStore,
        getUsersByIdFromStore
    ],
    (userIds, usersById) => userIds.map((userId) => usersById[userId]).sort((a, b) => {
        const sorter = makeStringSorter('name', 'asc');
        return (
            sorter({ name: `${a.firstName} ${a.lastName}` }, { name: `${b.firstName} ${b.lastName}` })
        );
    })
);

export const selectUserIds = createSelector(
    [
        selectUsers
    ],
    (users) => users.map((user) => user.id)
);

export const makeSelectUserById = () => createSelector(
    [
        getUserByIdFromStore,
    ],
    (user) => user
);

export const selectIsDeletePending = createSelector(
    [
        (state) => getUsersAsyncStatesFromStore(state).delete,
        (_, id) => id
    ],
    (asyncActions, userId) => _get(asyncActions, [userId, 'isPending'], false)
);

export const selectIsUpdateUserFirstNamePending = (state) => {
    const userNameState = getUsersAsyncStatesFromStore(state).updateUserName;
    return _get(userNameState, ['first', 'isPending'], false);
};

export const selectIsUpdateUserLastNamePending = (state) => {
    const userNameState = getUsersAsyncStatesFromStore(state).updateUserName;
    return _get(userNameState, ['last', 'isPending'], false);
};

export const selectLoggedInUser = createSelector(
    [
        getLoggedInUserId,
        getUsersByIdFromStore
    ],
    (userId, users) => users[userId]
);

export const selectLoggedInUserId = createSelector(
    [
        getLoggedInUserId
    ],
    (userId) => userId
);

const getSelectedUserSpaceIndex = (usersSpaces, spaceId) => {
    for (let i = 0; i < usersSpaces.length; i++) {
        if (usersSpaces[i].id === spaceId) {
            return i;
        }
    }
    return -1;
};

const createDefaultSelectedTags = (groupRestrictions, spaceId) => {
    const defaultSelectedTags = [];
    for (let j = 0; j < groupRestrictions.length; j++) {
        if (groupRestrictions[j].spaceId === spaceId) {
            defaultSelectedTags.push({
                id: `${groupRestrictions[j].id}`,
                name: groupRestrictions[j].name,
                spaceId: groupRestrictions[j].spaceId
            });
        }
    }
    return defaultSelectedTags;
};

export const selectIsResendUserInvitationPending = (state, userId) => {
    const userInvitationState = getUsersAsyncStatesFromStore(state).resendUserInvitation;
    return _get(userInvitationState, [userId, 'isPending'], false);
};

export const makeSelectUserEditInitialFormValues = () => createSelector(
    [
        (user) => user,
        (_, spaces) => spaces,
        (_, __, defaultTimezone) => defaultTimezone,
        (_, __, ___, loggedInSpace) => loggedInSpace
    ],
    (user, spaces, defaultTimezone, loggedInSpace) => {
        const initialValues = {};
        initialValues.id = '';
        initialValues.firstName = '';
        initialValues.lastName = '';
        initialValues.email = '';
        initialValues.isAdmin = false;
        initialValues.password = '';
        initialValues.passwordConfirmation = '';
        initialValues.timezone = defaultTimezone;
        if (user) {
            initialValues.id = user.id;
            initialValues.firstName = user.firstName;
            initialValues.lastName = user.lastName;
            initialValues.email = user.email;
            initialValues.isAdmin = user.admin;
            initialValues.timezone = user.timezone;
            // prepare the space values
            const { spaces: usersSpaces, groupRestrictions } = user;
            spaces.forEach((space) => {
                const spaceId = space.id;
                const userSpaceIndex = getSelectedUserSpaceIndex(usersSpaces, space.id);
                const spaceSelected = userSpaceIndex !== -1;
                const readOnlySpace = (spaceSelected === true) ? usersSpaces[userSpaceIndex].readOnly : false;
                const defaultSelectedTags = createDefaultSelectedTags(groupRestrictions, spaceId);
                initialValues[`space-${spaceId}`] = [{ spaceSelected, readOnlySpace, defaultSelectedTags }];
            });
        } else {
            spaces.forEach((space) => {
                initialValues[`space-${space.id}`] = [{ spaceSelected: space.id === loggedInSpace.id, readOnlySpace: false, defaultSelectedTags: [] }];
            });
        }
        return initialValues;
    }
);

export const makeSelectUsersByEmail = () => createSelector(
    [
        selectUsers,
        (_, emails) => emails
    ],
    (users, emails) => users.filter((user) => _includes(emails, user.email))
);
